import {React, useEffect} from "react"
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight"
import SeatingContracts_transparent from "../img/seating_contracts_transparent.png"
import SeatingContracts_iphone from "../img/seating_contracts_iphone14.png"
import WebsiteDevelopment from "../img/icons/device-desktop-code.png"
import CloudEnablement from "../img/icons/cloud-code.png"
import AutomationSolutions from "../img/icons/settings-automation.png"
import SeatingContractsLtd from "../img/testimonials/seatingcontractsltd.png"
import CaldwellPilates from "../img/testimonials/caldwellpilates.png"
import AWS from "../img/icons/aws.svg"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { ContactForm } from "./contactForm"
import About from "./about"
import { Link } from "react-router-dom"
import BasicFooterBar from "./basic_footer_bar"
import { Testimonial23 } from "./testimonal"
import AboutCards from "./cards"
export const Index = (props) => {
  const scrollToContact = () => {
    const contactSection = document.getElementById("contactScroll");
    contactSection.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  return (
    <>
      {/* 
    ------------------------------------------------
    HEADER SECTION
    ------------------------------------------------
    */}
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="container-fluid">
                <div className="row" style={{ height: "80vh" }}>
                  <div className="col-md-8 col-md-offset-2 intro-text d-flex flex-column justify-content-center align-items-center">
                    <h1>Seamless Solutions, Infinite Possibilities</h1>
                    <p>From Concept to the Cloud: Your Partner in Digital Transformation</p>
                    <div className="center">
                      <button onClick={scrollToContact} > 
                        <div class="svg-wrapper-1">
                          <div class="svg-wrapper">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path fill="currentColor" d="M8 4l8 8-8 8-1.41-1.41L12.17 12 6.59 6.41z"></path>
                            </svg>
                          </div>
                        </div>
                        <span>CONTACT US</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* 
    ------------------------------------------------
    ABOUT SECTION
    ------------------------------------------------
    */}
      <div id="about">
        {/* <About /> */}
      </div>

      {/* <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-8">
              <img src={SeatingContracts_transparent} alt="Your Logo" id="image-about" />
            </div>
            <div className="col-xs-12 col-md-4">
              <div className="about-text">
                <h2>About Us</h2>

                <div className="list-style">
                  Our passion lies in minimalist design. We excel in utilising cutting-edge technologies and cloud solutions to build dynamic websites. <br />
                  <br />
                  <ul>
                    <li>Custom Web Development</li>
                    <li>E-commerce Solutions</li>
                    <li>Mobile App Integration</li>
                    <li>User-friendly Interfaces</li>
                    <li>Scalable Infrastructure</li>
                    <li>Performance Optimization</li>
                    <li>Data Security and Compliance</li>
                    <li>SEO Visibility</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* 
    ------------------------------------------------
    SERVICES SECTION
    ------------------------------------------------
    */}
      <div id="services">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
            <p>Seamless Solutions, Superior Results – Fueling Your Business Evolution</p>
          </div>

          {/* <div className="row">
            <div className="col-md-4">
               <div className="col-md-4" style={{ backgroundColor: "rgba(255, 255, 255, 0.05)", borderBottom: "1px solid white", padding: "20px" }}> 
              <img src={WebsiteDevelopment} style={{ width: "5em" }} />
              <div className="service-desc">
                <h3>Website Development</h3>
                <p>From visually appealing designs to seamless user experiences, we leverage the latest technologies to ensure your online presence is not only captivating but also functional.</p>
                <br />
                <Link to="" className="page-scroll">
                  <a>Download Workbook ➔</a>
                </Link>
                <br/>
                <br/>
                <Link to="/website-development" className="page-scroll">
                  <a>Learn More ➔</a>
                </Link>


              </div>
            </div>
            <div className="col-md-4">
              <img src={CloudEnablement} style={{ width: "5em" }} />
              <div className="service-desc">
                <h3>Cloud Enablement</h3>
                <p>Embrace the power of the cloud with our comprehensive cloud enablement services. We assist through the process of migrating, managing, and optimising operations on cloud platforms.</p>
                <br />
                <Link to="" className="page-scroll">
                  <a>Download Workbook ➔</a>
                </Link>
                <br/>
                <br/>
                <Link to="/cloud-enablement" className="page-scroll">
                  <a>Learn More ➔</a>
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <img src={AutomationSolutions} style={{ width: "5em" }} />
              <div className="service-desc">
                <h3>RPA Solutions</h3>
                <p>Our specialised automation solutions streamline operations, freeing your team for strategic endeavors, improving productivity and enhancing overall efficiency in your workflow.</p>
                <br />
                <Link to="" className="page-scroll">
                  <a>Download Workbook ➔</a>
                </Link>
                <br/>
                <br/>
                <Link to="/rpa-solutions" className="page-scroll">
                  <a>Learn More ➔</a>
                </Link>
              </div>
            </div>
          </div> */}
      <AboutCards/>

        </div>
        
      </div>
{/* <Testimonial23></Testimonial23> */}



      {/* 
    ------------------------------------------------
    TESTIMONALS SECTION
    ------------------------------------------------
    */}
      {/* 
      <div id="testimonials">
        <div className="container">
          <div className="section-title text-center">
            <h2>Client Feedback</h2>
            <p>See what our happy clients have to say</p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="testimonial">
                <div className="testimonial-image">
                  <img src={CaldwellPilates} alt="Your Logo" className="logo-image" />
                </div>
                <div className="testimonial-content">
                  <p>Emma Caldwell, Caldwell Pilates</p>
                  <div className="testimonial-meta">
                    The seamless integration of online purchasing capabilities has significantly contributed to our business growth. <br />
                    <br />
                    We highly value our partnership and anticipate continued success with Dotco at our side. The website is fast to load and easy to use.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="testimonial">
                <div className="testimonial-image">
                  <img src={SeatingContractsLtd} alt="Seating Contracts Ltd" className="logo-image" />
                </div>
                <div className="testimonial-content">
                  <p>Wayne Lewis, Seating Contracts Ltd</p>
                  <div className="testimonial-meta">
                    Dotco have demonstrated a remarkable level of professionalism and expertise. <br />
                    <br />
                    The team actively proposed and implemented a modern website hosted in AWS, ensuring our website was making use of the most advanced cloud technologies.
                  </div>
                </div>
              </div>
            </div>
          </div>   
        </div>  
      </div>   
    */}
      {/* 
    ------------------------------------------------
    CONTACT SECTION
    ------------------------------------------------
    */}
      <div >
        <GoogleReCaptchaProvider reCaptchaKey="6LeTi0spAAAAAOYvyIeasK0A8DhmSDmj6Ocw4yCN">
          <ContactForm />
        </GoogleReCaptchaProvider>
      </div>
      <BasicFooterBar />
    </>
  )
}
