import React, { useEffect } from "react";
import webdev from "./../img/WebsiteDevelopment.png"

export const Services = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <header id="header">
          <div className="services">
            <div className="overlay">
              <div className="container">
                <div className="container-fluid">
                  <div className="row" style={{ height: "40vh" }}>
                    <div className="col-md-8 col-md-offset-2 about-text d-flex flex-column justify-content-center align-items-center">
                      <h1>Services</h1>
                      {/* <p>Transforming work efficiency through task automation</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div className="services_content">
        <div className="container">
        <div className="row">
            <div className="col-md-12">
              <div className="card mb-3">
                <div className="card-body ">
                <div className="section-title">
            <h2>Website Development</h2>
            <p>Seamless Solutions, Superior Results – Fueling Your Business Evolution</p>
            <p>Superior Results – Fueling Your Business Evolution</p>
          </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-3">
                <div className="card-body">
                <div className="section-title">
            <h2>Cloud Enablement</h2>
            <p>Seamless Solutions, Superior Results – Fueling Your Business Evolution</p>
            <p>Superior Results – Fueling Your Business Evolution</p>
          </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-3">
                <div className="card-body">
                <div className="section-title">
            <h2>Automation Solutions (RPA)</h2>
            <p>Seamless Solutions, Superior Results – Fueling Your Business Evolution</p>
            <p>Superior Results – Fueling Your Business Evolution</p>
          </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
