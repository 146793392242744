import {React, useEffect} from "react";
import BasicFooterBar from "../basic_footer_bar";

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  return (
    <>
    <div className="container privacy-policy" style={{ paddingTop: "200px", color: "#fff" }}>
      <div className="row" >
        <div className="col-lg-12">
          <h2 style={{ color: "#fff" }}>Internet Privacy & Cookies Policy</h2>
          <br/>
          <br/>
          <p>
            This website is the property of Dotco Group. <br/><br/>We take the privacy
            of all visitors to this Website very seriously and therefore set
            out in this privacy and cookies policy our position regarding
            certain privacy matters and the use of cookies on this Website.
            This policy covers all data that is shared by a visitor with us
            whether directly via www.dotcogroup.com or via email. <br/><br/>This policy
            provides an explanation as to what happens to any personal data
            that you share with us, or that we collect from you either
            directly via this Website or via email. Certain businesses are
            required under the data protection act to have a data controller.
            For the purpose of the Data Protection Act 1998, our data
            controller is George Burke.
          </p>
          <h3 style={{ color: "#fff" }}>Information we collect</h3>
          <p>
            In operating our Website, we may collect and process the following
            data about you:
          </p>
          <ul><br/>
            <li style={{ color: "#D3D3D3" }}> 
              - Information that you provide by filling in forms on our Website,
              such as when you register to receive information such as a
              newsletter or contact us via the contact us page.
            </li ><br/>
            <li style={{ color: "#D3D3D3" }}>- Information provided to us when you communicate with us for any reason.</li>
          </ul>
        </div>
      </div>

    </div>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
    <BasicFooterBar />
    
    </>
  );
};

export default PrivacyPolicy;
