import React from "react"
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import ContactForm from "../components/contactForm"

const styles = {
  container: {
    display: "flex",
    justifyContent: "center", // Horizontally center the content
    alignItems: "center", // Vertically center the content
  }
}

export const ContactPage = (props) => {
  return (
    <div style={styles.container}>
      <GoogleReCaptchaProvider reCaptchaKey="6LeTi0spAAAAAOYvyIeasK0A8DhmSDmj6Ocw4yCN">
        <ContactForm />
      </GoogleReCaptchaProvider>
    </div>
  )
}

export default ContactPage
