import {React, useEffect} from "react"
import TeamAbout from "./team";

export const About = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  return (
    <div>
    <header id="header">
    <div className="about">
      <div className="overlay">
        <div className="container">
          <div className="container-fluid">
            <div className="row" style={{ height: "40vh" }}>
              <div className="col-md-8 col-md-offset-2 about-text d-flex flex-column justify-content-center align-items-center">
                <h1>Meet the team</h1>
                <p>Transforming work efficiency through task automation</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TeamAbout/>
    </div>
  </header>
  </div>
  );
};
export default About;
