import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const Navigation = (props) => {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav
      id="menu"
      className={`navbar navbar-default navbar-fixed-top ${
        scrolled || isMenuOpen ? "scrolled" : ""
      }`}
    >
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            onClick={toggleMenu}
            style={{ display: "block" }}
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>

          <Link
            to="/"
            className="navbar-brand page-scroll"
            style={{ display: "flex", alignItems: "center" }}
            onClick={closeMenu}
          >
            <span style={{ verticalAlign: "middle" }}>Dotco Group</span>
          </Link>
        </div>

        <div
          className={`collapse navbar-collapse ${
            isMenuOpen ? "show" : ""
          }`}
          id="bs-example-navbar-collapse-1"
          style={{
            maxHeight: isMenuOpen ? "1000px" : "0",
            transition: "max-height 0.5s ease-in-out" // Adjust the duration and easing as needed
          }}
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link
                to="/about"
                className="page-scroll"
                onClick={closeMenu}
              >
                About
              </Link>
            </li>
            {/* <li>
              <Link
                to="/services"
                className="page-scroll"
                onClick={closeMenu}
              >
                Services
              </Link>
            </li> */}
            <li>
              <Link
                to="/contact"
                className="page-scroll contact-link"
                onClick={closeMenu}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
