import React, { useState, useEffect } from "react"
import { Navigation } from "./components/navigation"
import { Header } from "./components/header"
import { Features } from "./components/features"
import { About } from "./components/about"
import { Index } from "./components/index"
import { Services } from "./components/services"
import { Gallery } from "./components/gallery"
import { Testimonials } from "./components/testimonials"

import PrivacyPolicy from "./components/assets/privacypolicy"
import TermsOfService from "./components/assets/termsofservice"

import Contact from "./pages/contact"

// import VantaFog from "./components/assets/vanta"

import JsonData from "./data/data.json"
import SmoothScroll from "smooth-scroll"
import "./App.css"
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom"
import WebsiteDevelopment from "./components/website_development"
import RPASolutions from "./components/rpa_solutions"
import CloudEnablement from "./components/cloud_enablement"

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true
})

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <div>
      <Router>
        <Navigation />
        {/* <VantaFog /> */}
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />

          <Route path="/website-development" element={<WebsiteDevelopment />} />
          <Route path="/rpa-solutions" element={<RPASolutions />} />
          <Route path="/cloud-enablement" element={<CloudEnablement />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
