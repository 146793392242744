import React from "react"

const BasicFooterBar = () => (
  <div className="footer-copyright text-center py-3 white" style={{ backgroundColor: "rgba(0, 0, 0, 0.1)", padding: "10px 0", fontSize: "8pt", color: "#fff" }}>
    © 2024 Copyright
    <a href="https://dotcogroup.com/"> Dotco Group</a>
  </div>
)

export default BasicFooterBar
