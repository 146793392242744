import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import WebsiteDevelopement from "./../img/website_development.jpg"
import RPA from "./../img/rpa.jpg"
import LinesOne from "./../img/lines_one.png"
import LinesTwo from "./../img/lines_two.png"
import LinesThree from "./../img/lines_three.png"
import { Link } from "react-router-dom"

// Define card data in JSON array
const cardData = [
  {
    title: "Website Development",
    description: "From visually appealing designs to seamless user experiences, we leverage the latest technologies to ensure your online presence is not only captivating but also functional.    ",
    imageUrl: LinesOne
  },
  {
    title: "Cloud Enablement",
    description: "Embrace the power of the cloud with our comprehensive cloud enablement services. We assist through the process of migrating, managing, and optimising operations on cloud platforms.    ",
    imageUrl: LinesTwo
  },
  {
    title: "Automation Solutions (RPA)",
    description: "Our specialised automation solutions streamline operations, freeing your team for strategic endeavors, improving productivity and enhancing overall efficiency in your workflow.    ",
    imageUrl: LinesThree}
];

const ThreeCardGrid = () => {
  return (
    <div className="container">
      <Grid container spacing={4}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card style={{ height: "350px", position: "relative" }}>
              <div
                style={{
                  backgroundImage: `url(${card.imageUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 0,
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.3)", // Adjust opacity here
                  zIndex: 1,
                }}
              />
              <CardContent style={{ position: "relative", zIndex: 2 }}>
                <h3>{card.title}</h3>
                <p>{card.description}</p>
                <br/>
                {/* <Link to="/services" className="page-scroll">
                  <a>Learn More ➔</a>
                </Link> */}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};


export default ThreeCardGrid;
