import React from "react"

export const TermsOfService = () => {
  return (
    <div style={{ padding: "20px" }}>
      <h1>Privacy Policy</h1>
      <p>Last updated: [Insert Date]</p>

      <h2>Introduction</h2>
      <p>Welcome to [Your Company Name]. This privacy policy explains how we collect, use, and share information when you visit or use our website.</p>

      <h2>Information We Collect</h2>
      <p>We collect information that you provide to us directly, such as when you create an account, make a purchase, or communicate with us. This may include your name, email address, phone number, and payment information.</p>

      <h2>How We Use Your Information</h2>
      <p>We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to prevent fraud and abuse.</p>

      <h2>Sharing of Information</h2>
      <p>We do not share your personal information with third parties, except as necessary to provide our services, comply with the law, or protect our rights.</p>

      <h2>Your Rights and Choices</h2>
      <p>You have the right to access, update, and delete your personal information. Please contact us to exercise these rights.</p>

      <h2>Changes to This Policy</h2>
      <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on our website.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions about this privacy policy, please contact us at [Your Contact Information].</p>
    </div>
  )
}

export default TermsOfService
