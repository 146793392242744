import React from "react"

const BasicFooter = () => (
  <footer className="page-footer font-small blue pt-4" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)", paddingTop: "20px", color: "#fff", fontSize: "9pt" }}>
    <div className="container" style={{ paddingBottom: "40px" }}>
      <div className="row">
        <div className="col-md-6 mt-md-0 mt-3">
          <h5 className="text-uppercase">Footer Content</h5>
          <p>Here you can use rows and columns to organize your footer content.</p>
        </div>

        <div className="col-md-3 mb-md-0 mb-3">
          <h5 className="text-uppercase">CONTENT</h5>
          <ul className="list-unstyled">
            <li>
              <a href="#!">Home</a>
            </li>
            <li>
              <a href="#!">Services</a>
            </li>
            <li>
              <a href="#!">Insights</a>
            </li>
            <li>
              <a href="#!">Contact</a>
            </li>
          </ul>
        </div>

        <div className="col-md-3 mb-md-0 mb-3">
          <h5 className="text-uppercase">Links</h5>
          <ul className="list-unstyled">
            <li>
              <a href="#!">Terms and Conditions</a>
            </li>
            <li>
              <a href="#!">Privacy Policy</a>
            </li>
            {/* <li>
              <a href="#!">Link 3</a>
            </li>
            <li>
              <a href="#!">Link 4</a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>

    <div className="footer-copyright text-center py-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.1)", padding: "10px 0", fontSize: "8pt" }}>
      © 2024 Copyright
      <a href="https://dotcogroup.com/"> Dotco Group</a>
    </div>
  </footer>
)

export default BasicFooter
