// Import necessary dependencies
import React from "react"
import SeatingContracts from "../img/seating_contracts.png"
import BasicFooter from "./basic_footer"

// About component
export const RPASolutions = () => {
  return (
    <>
      <div id="about">
        <div className="container" style={{ marginTop: "10rem" }}>
          <div className="row">
            <div className="col-xs-12 col-md-8">
              {/* Display an image */}
              <img src={SeatingContracts} alt="Seating Contracts" id="image-about" />
            </div>
            <div className="col-xs-12 col-md-4">
              {/* About Us text */}
              <div className="about-text">
                <h2>RPA Solutions</h2>
                <div className="list-style">
                  <p>Our passion lies in minimalist design. We excel in utilizing cutting-edge technologies and cloud solutions to build dynamic websites.</p>
                  <p>Our services include:</p>
                  <ul>
                    <li>Custom Web Development</li>
                    <li>E-commerce Solutions</li>
                    <li>Mobile App Integration</li>
                    <li>User-friendly Interfaces</li>
                    <li>Scalable Infrastructure</li>
                    <li>Performance Optimization</li>
                    <li>Data Security and Compliance</li>
                    <li>SEO Visibility</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BasicFooter />
    </>
  )
}
export default RPASolutions
