import React, { useState, useEffect } from "react"
import AWS from "../img/icons/aws.svg"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { TextField, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom"
import DoneIcon from "@mui/icons-material/Done"

const initialState = {
  name: "",
  email: "",
  message: "",
  subject: "general"
}

export const ContactForm = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  const [formState, setFormState] = useState(initialState)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [submitted, setSubmitted] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormState((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () => setFormState({ ...initialState })

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available")
      return
    }

    const recaptchaToken = await executeRecaptcha("contact_form")

    const apiEndpoint = "https://waz6vr8oma.execute-api.eu-north-1.amazonaws.com/dotco-api-v1/contact"

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...formState, recaptchaToken })
      })

      if (response.ok) {
        console.log("Email sent successfully:", await response.json())
        setSubmitted(true)
        scheduleResetState()
        toast.success("Message sent successfully!")
        clearState()
      } else {
        console.error("Server responded with a non-OK status")
        toast.error("Failed to send message. Please try again later.")
      }
    } catch (error) {
      console.error("Error sending email:", error)
      toast.error("An error occurred. Please try again later.")
    }
  }

  const scheduleResetState = () => {
    setTimeout(() => {
      setSubmitted(false)
    }, 5000)
  }

  return (
    <div>
      <ToastContainer />

      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row" style={{ paddingTop: "100px" }} id="contactScroll"> 
              <div className="section-title" >
                <h2>Contact</h2>
                <p>We will get back to you as soon as possible</p>
              </div>
              <div className="section-title"></div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <TextField id="name" name="name" label="Name" variant="filled" fullWidth required onChange={handleChange} value={formState.name} placeholder="Name" />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <TextField id="email" name="email" label="Email" variant="filled" fullWidth required onChange={handleChange} value={formState.email} placeholder="Email" type="email" />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <FormControl variant="filled" fullWidth required>
                        <Select labelId="subject-label" id="subject" name="subject" value={formState.subject} onChange={handleChange}>
                          <MenuItem value="general">General Inquiry</MenuItem>
                          <MenuItem value="Website Development">Website Development</MenuItem>
                          {/* <MenuItem value="Website Development Reduced Rate">Launch Special: Website Development at Reduced Rate</MenuItem> */}
                          <MenuItem value="Cloud Migration">Cloud Migration</MenuItem>
                          <MenuItem value="Customer Support">Customer Support</MenuItem>
                        </Select>
                      </FormControl>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <TextField id="message" name="message" label="Message" multiline rows={8} placeholder="Message" required onChange={handleChange} value={formState.message} variant="filled" fullWidth />
                  <p className="help-block text-danger"></p>
                </div>
                <button type="submit" className="btn btn-custom btn-lg">
                  {submitted ? (
                    <span>
                      <DoneIcon className="animated-checkmark" style={{ fontSize: "18px" }} />
                    </span>
                  ) : (
                    <span className="animated-fadeout">Send Message</span>
                  )}
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info ">
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-location-arrow"></i> Location
                </span>
                Based in Western Australia
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>
                (+61) 452 163 399 
              </p>
            </div>
            {/* <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>
                contact@dotcogroup.com
              </p>
            </div> */}
            <hr />
            <div className="contact-item">
              <p>
                <i class="fa fa-instagram"></i>
                <i class="fa fa-facebook"></i>
                <i class="fa fa-twitter"></i>
                <i class="fa fa-linkedin"></i>
              </p>
            </div>
            <div className="contact-item">
              <span>
                Powered by <img src={AWS} style={{ fill: "#fff", width: "2em", padding: "4px" }} alt="AWS Logo" />
              </span>
            </div>
            <div className="contact-item">
              <p style={{ fontSize: "7pt" }}>
                <Link to="/privacy-policy">PRIVACY POLICY</Link> | <Link to="/terms-of-service">TERMS OF SERVICE</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
