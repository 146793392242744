import { Link } from "react-router-dom"
import React from "react"
import BasicFooter from "./basic_footer"

export const WebsiteDevelopment = () => {
  return (
    <>
      <div id="about">
        {/* Process Flow */}
        <div className="container" style={{ marginTop: "10rem" }}>
          <div className="row justify-content-between align-items-center">
            <h3>DEVELOPMENT PROCESS</h3>

            <div className="col-xs-12 col-md-4 mt-4">
              <div className="about-text">
                <h3 style={{ color: "#608dfd" }}>01</h3>
                <h4>Engage</h4>
                <p>Engage in tailored discovery sessions crafted for your business needs. Gain insights through comprehensive market research and competitor analysis</p>
              </div>
            </div>
            <div className="col-xs-12 col-md-4">
              <div className="about-text">
                <h3 style={{ color: "#608dfd" }}>02</h3>
                <h4>Create</h4>
                <p>Embark on the design journey with our creative team, crafting wireframes and final designs infused with insights from our discovery phase</p>
              </div>
            </div>
            <div className="col-xs-12 col-md-4">
              <div className="about-text">
                <h3 style={{ color: "#608dfd" }}>03</h3>
                <h4>Build</h4>
                <p>Witness the culmination of meticulous craftsmanship as our development team brings concepts to life with pixel-perfect code, ensuring seamless performance across all devices</p>
              </div>
            </div>
          </div>
        </div>
        {/* New Section */}
        <div className="container" style={{ marginTop: "10rem" }}>
          <div className="row justify-content-between align-items-center">
            <h3>Unique online experiences</h3>
            <div className="col-md-8">
              <img src="path_to_your_image" alt="Tall Image" style={{ maxWidth: "100%" }} />
            </div>
            <div className="col-md-4 mt-4">
              <div className="about-text">
                <h3>Point 1</h3>
                <p>Description of point 1</p>
                <h3>Point 2</h3>
                <p>Description of point 2</p>
                <h3>Point 3</h3>
                <p>Description of point 3</p>
                <h3>Point 4</h3>
                <p>Description of point 4</p>
                <h3>Point 5</h3>
                <p>Description of point 5</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BasicFooter />
    </>
  )
}

export default WebsiteDevelopment
