import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import GeorgeBurke from "../img/george_burke.jpeg";
import GeorgeHodgson from "../img/george_hodgson.jpeg";
import Hasan from "../img/hasan.jpg";
import BasicFooterBar from "./basic_footer_bar";

// Define card data in JSON array
const cardData = [
  {
    title: "George Burke",
    description: "CEO, CTO",
    location: "Perth WA",
    imageUrl: GeorgeBurke,
  },
  {
    title: "Hasan Hadzimejlic",
    description: "CFO",
    location: "Dubai UAE",
    imageUrl: Hasan,
  }
];

const TeamAbout = () => {
  return (
    <>
      <div className="container about-mobile">
        <Grid container spacing={4} justifyContent="center">
          {/* Displaying all three people in a single row */}
          
          {cardData.map((person, index) => (
            <Grid key={index} item xs={12} md={4} lg={4}>
              <div style={{ textAlign: "center" }}>
                <img
                  src={person.imageUrl}
                  alt={person.title}
                  style={{
                    width: "100%",
                    maxWidth: "150px",
                    borderRadius: "50%",
                    filter: "grayscale(100%)"
                  }}
                />
                <h3>{person.title}</h3>
                <Typography color="textSecondary">
                {person.location}
                <br/>
                  {person.description}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>

        <div style={{ textAlign: "center", marginTop: "60px" }}>
          <hr style={{ width: "100%", maxWidth: "800px", margin: "auto" }} />
        </div>

        {/* Mission Statement Section */}
        <Box textAlign="center">
          <h3>Our Mission</h3>
          <p style={{ width: "100%", maxWidth: "800px", margin: "auto" }} >With a commitment to quality and innovation, we stand as your trusted ally in navigating the ever-changing landscape of technology, driving towards a brighter, interconnected world.</p>

</Box><div style={{ textAlign: "center", marginTop: "40px", marginBottom: "80px" }}>
          <hr style={{ width: "100%", maxWidth: "800px", margin: "auto" }} />
        </div>
        <BasicFooterBar />
      </div>
    </>
  );
};

export default TeamAbout;
